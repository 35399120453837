// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@include mat.core();

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$sedgwick-typography: mat.m2-define-typography-config(
  $font-family: "Source Sans Pro",
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$md-sedgwick-blue-palette: (
  50: #e2f3fa,
  100: #b6e1f3,
  200: #86ceeb,
  300: #55bae3,
  400: #30abdd,
  500: #0c9cd7,
  600: #0a94d3,
  700: #088acd,
  800: #0680c7,
  900: #036ebe,
  A100: #e6f3ff,
  A200: #b3dbff,
  A400: #80c3ff,
  A700: #67b7ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-cobalt-blue: (
  50: #e5e9ef,
  100: #bdc7d7,
  200: #92a2bc,
  300: #667da1,
  400: #45618c,
  500: #244578,
  600: #203e70,
  700: #1b3665,
  800: #162e5b,
  900: #0d1f48,
  A100: #80a1ff,
  A200: #4d7bff,
  A400: #1a55ff,
  A700: #0142ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$my-sedgwick-5-primary: mat.m2-define-palette($md-sedgwick-blue-palette);
$my-sedgwick-5-accent: mat.m2-define-palette($md-cobalt-blue);
$my-sedgwick-5-warn: mat.m2-define-palette(mat.$m2-red-palette);

$my-sedgwick-5-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-sedgwick-5-primary,
      accent: $my-sedgwick-5-accent,
      warn: $my-sedgwick-5-warn,
    ),
    typography: $sedgwick-typography,
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($my-sedgwick-5-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", "Helvetica Neue", sans-serif;
}

mat-mdc-form-field {
  width: 100%;
}

.app-high-density {
  @include mat.form-field-density(-2);
}

.app-full-width {
  width: 100%;
}

.app-overlay-light-backdrop {
  /* background: #00000006;*/
  background: rgb(0 0 0 / 23%);
}

.app-input-button {
  color: rgba(0, 0, 0, 0.54);
}

.app-mat-mdc-form-field-no-margin {
  margin-bottom: -1.25em;
}

.spinner-overlay mat-mdc-dialog-container {
  box-shadow: none;
  background: rgba(0, 0, 0, 0);
}

.spinner-overlay-backdrop {
  background: rgba(0, 0, 0, 0.08);
}

.mdc-dialog__content:has(.scroller) {
  height: 60vh;
  overflow: hidden;
}

$color-primary: map-get($my-sedgwick-5-primary, 500);
$color-accent: map-get($my-sedgwick-5-accent, 500);
$color-accent-darker: map-get($my-sedgwick-5-accent, 600);
$color-warn: map-get($my-sedgwick-5-warn, 500);

$color-sedgwick-blue: #007dbc;
$color-sedgwick-green: #3f7c4f;
$color-teal: #035e81;
$color-teal-hover: #175c77;
$color-red: #c24533;

$color-orange: #e96c3d;
$color-green: #3f7c4f;
$color-white: #ffffff;
$color-cobalt-blue: #244578;
$color-sky-blue: #a0d1ec;
$color-slate: #67707a;
$color-slate-500: #383d4c;
$color-slate-600: #171c20;
$color-light-grey: #e9edf2;
$color-primary: #00354c;
$color-purple: #9b57a2;
$color-input-grey: #67707a;

:root {
  --color-primary: #107bb7;
  --color-accent: #244578;
  --color-warn: #c24533;
  --color-sedgwick-blue: #007dbc;
  --color-dark-blue: #01334c;
  --color-teal: #035e81;
  --color-teal-hover: #175c77;
  --color-red: #c24533;
  --color-orange: #e96c3d;

  --color-sedgwick-green: #3f7c4f;
  --color-green: #3f7c4f;
  --color-white: #ffffff;
  --color-cobalt-blue: #244578;
  --color-sky-blue: #a0d1ec;
  --color-slate: #67707a;
  --color-slate-500: #383d4c;
  --color-slate-600: #171c20;
  --color-light-grey: #e9edf2;
  --color-purple: #9b57a2;
  --color-input-grey: #67707a;
  --color-border-grey: #dcdcdc;
  --fc-today-bg-color: #ffffff !important;
  --fc-button-bg-color: #007dbc !important;
  --fc-button-border-color: #007dbc !important;
  --fc-button-hover-bg-color: #035e81 !important;
  --fc-button-hover-border-color: #035e81 !important;
  --fc-button-active-bg-color: #035e81 !important;
  --fc-button-active-border-color: #035e81 !important;
  --fc-border-color: #efefef;
}

/*.fc-day-today {
  border: 3px solid $color-sedgwick-blue !important;
  margin: 2px;
}
.fc-day-today .fc-daygrid-day-top{
  border-top:3px solid $color-sedgwick-blue !important;
}*/
.fc-day-today .fc-daygrid-day-frame {
  border: 3px solid $color-sedgwick-blue !important;
}

.fc-toolbar-title {
  color: #000000de !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

a.fc-daygrid-day-number {
  text-decoration: none !important;
  cursor: default !important;
}

a.fc-col-header-cell-cushion {
  text-decoration: none;
}

a {
  color: $color-sedgwick-blue;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  color: $color-teal;
  text-decoration: solid underline $color-teal 2px;
  cursor: pointer;
}

.divider-t {
  margin: 10px 0 10px 0;
  border-top: 2px solid $color-light-grey;
}

.divider-b {
  margin: 10px 0 10px 0;
  border-bottom: 2px solid $color-light-grey;
}

.divider-r {
  margin: 0 10px 0 10px;
  border-right: 2px solid $color-light-grey;
}

.divider-l {
  margin: 0 10px 0 10px;
  border-left: 2px solid $color-light-grey;
}

.snackbar-warning .mdc-snackbar__surface {
  background: $color-orange !important;
  color: white !important;
}

.snackbar-success .mdc-snackbar__surface {
  background: $color-green !important;
  color: white !important;
}

.snackbar-info .mdc-snackbar__surface {
  background: $color-sedgwick-blue !important;
  color: white !important;
}

.snackbar-basic .mdc-snackbar__surface {
  background: white !important;
  color: #000000de !important;
}

.snackbar-error .mdc-snackbar__surface {
  background: $color-red !important;
  color: white !important;
}

.snackbar-error-inverse .mdc-snackbar__surface {
  color: $color-red !important;
  background: white !important;
  border: 1px solid $color-red;
}

.breadcrumb {
  color: #000000de;
  opacity: 0.6;
  min-height: 18px;
}

.breadcrumb-font-size {
  font-size: 14pt !important;
}

// margin classes - use these to keep spacing consistent
// spacing
$app-size-1: 8px;
$app-size-2: 16px;
$app-size-3: 24px;
$app-size-4: 32px;
$app-size-5: 48px;
$app-size-6: 56px;
$app-size-7: 64px;
$app-size-8: 72px;

.app-p1 {
  padding: $app-size-1;
}
.app-pb1 {
  padding-bottom: $app-size-1;
}
.app-pt1 {
  padding-top: $app-size-1;
}
.app-pl1 {
  padding-left: $app-size-1;
}
.app-pr1 {
  padding-right: $app-size-1;
}
.app-p2 {
  padding: $app-size-2;
}
.app-pb2 {
  padding-bottom: $app-size-2;
}
.app-pt2 {
  padding-top: $app-size-2;
}
.app-pl2 {
  padding-left: $app-size-2;
}
.app-pr2 {
  padding-right: $app-size-2;
}
.app-p3 {
  padding: $app-size-3;
}
.app-pb3 {
  padding-bottom: $app-size-3;
}
.app-pt3 {
  padding-top: $app-size-3;
}
.app-pl3 {
  padding-left: $app-size-3;
}
.app-pr3 {
  padding-right: $app-size-3;
}
.app-p4 {
  padding: $app-size-4;
}
.app-pb4 {
  padding-bottom: $app-size-4;
}
.app-pt4 {
  padding-top: $app-size-4;
}
.app-pl4 {
  padding-left: $app-size-4;
}
.app-pr4 {
  padding-right: $app-size-4;
}
.app-p5 {
  padding: $app-size-5;
}
.app-pb5 {
  padding-bottom: $app-size-5;
}
.app-pt5 {
  padding-top: $app-size-5;
}
.app-pl5 {
  padding-left: $app-size-5;
}
.app-pr5 {
  padding-right: $app-size-5;
}
.app-p6 {
  padding: $app-size-6;
}
.app-pb6 {
  padding-bottom: $app-size-6;
}
.app-pt6 {
  padding-top: $app-size-6;
}
.app-pl6 {
  padding-left: $app-size-6;
}
.app-pr6 {
  padding-right: $app-size-6;
}
.app-p7 {
  padding: $app-size-7;
}
.app-pb7 {
  padding-bottom: $app-size-7;
}
.app-pt7 {
  padding-top: $app-size-7;
}
.app-pl7 {
  padding-left: $app-size-7;
}
.app-pr7 {
  padding-right: $app-size-7;
}
.app-p8 {
  padding: $app-size-8;
}
.app-pb8 {
  padding-bottom: $app-size-8;
}
.app-pt8 {
  padding-top: $app-size-8;
}
.app-pl8 {
  padding-left: $app-size-8;
}
.app-pr8 {
  padding-right: $app-size-8;
}
.app-m1 {
  margin: $app-size-1;
}
.app-mb1 {
  margin-bottom: $app-size-1;
}
.app-mt1 {
  margin-top: $app-size-1;
}
.app-ml1 {
  margin-left: $app-size-1;
}
.app-mr1 {
  margin-right: $app-size-1;
}
.app-m2 {
  margin: $app-size-2;
}
.app-mb2 {
  margin-bottom: $app-size-2;
}
.app-mt2 {
  margin-top: $app-size-2;
}
.app-ml2 {
  margin-left: $app-size-2;
}
.app-mr2 {
  margin-right: $app-size-2;
}
.app-m3 {
  margin: $app-size-3;
}
.app-mb3 {
  margin-bottom: $app-size-3;
}
.app-mt3 {
  margin-top: $app-size-3;
}
.app-ml3 {
  margin-left: $app-size-3;
}
.app-mr3 {
  margin-right: $app-size-3;
}
.app-m4 {
  margin: $app-size-4;
}
.app-mb4 {
  margin-bottom: $app-size-4;
}
.app-mt4 {
  margin-top: $app-size-4;
}
.app-ml4 {
  margin-left: $app-size-4;
}
.app-mr4 {
  margin-right: $app-size-4;
}
.app-m5 {
  margin: $app-size-5;
}
.app-mb5 {
  margin-bottom: $app-size-5;
}
.app-mt5 {
  margin-top: $app-size-5;
}
.app-ml5 {
  margin-left: $app-size-5;
}
.app-mr5 {
  margin-right: $app-size-5;
}
.app-m6 {
  margin: $app-size-6;
}
.app-mb6 {
  margin-bottom: $app-size-6;
}
.app-mt6 {
  margin-top: $app-size-6;
}
.app-ml6 {
  margin-left: $app-size-6;
}
.app-mr6 {
  margin-right: $app-size-6;
}
.app-m7 {
  margin: $app-size-7;
}
.app-mb7 {
  margin-bottom: $app-size-7;
}
.app-mt7 {
  margin-top: $app-size-7;
}
.app-ml7 {
  margin-left: $app-size-7;
}
.app-mr7 {
  margin-right: $app-size-7;
}
.app-m8 {
  margin: $app-size-8;
}
.app-mb8 {
  margin-bottom: $app-size-8;
}
.app-mt8 {
  margin-top: $app-size-8;
}
.app-ml8 {
  margin-left: $app-size-8;
}
.app-mr8 {
  margin-right: $app-size-8;
}

.app-header-1 {
  font-size: 26px;
  color: #171c20;
  font-weight: bold;
}

.app-header-2 {
  font-size: 20px;
  color: #171c20;
  font-weight: bold;
}

.app-header-3 {
  font-size: 16px;
  color: #171c20;
  font-weight: bold;
}

.app-header-4 {
  font-size: 16px;
  color: #171c20;
  font-weight: bold;
}

.app-sidenav-header {
  font-size: 14px;
  color: $color-sedgwick-blue;
  font-weight: bold;
}

.app-header-3-line {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 10px;
  margin: 10px 0 10px 0;
  font-size: 16px;
  font-weight: bold;
  color: var(--color-sedgwick-blue);
  min-height: 20px;
}

.app-button-margin {
  margin-right: 6px;
}

.app-blue-alert {
  font-size: 10px;
  font-weight: bold;
  color: white;
  background-color: var(--color-sedgwick-blue);
  border-radius: 16px;
  padding: 4px 8px 4px 8px;
  margin-left: 4px;
}

.app-dlg-padding .overlay {
  padding: 20px;
}
.app-dlg-padding .mdc-dialog__surface {
  overflow: hidden;
}

.app-blue-green {
  font-size: 10px;
  font-weight: bold;
  color: white;
  background-color: var(--color-sedgwick-green);
  border-radius: 16px;
  padding: 4px 8px 4px 8px;
  margin-left: 4px;
}

.app-indicator {
  font-size: 10px;
  font-weight: bold;
  color: white;
  background-color: var(--color-green);
  border-radius: 4px;
  padding: 4px 6px 4px 6px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.app-indicator-blue {
  font-size: 10px;
  font-weight: bold;
  color: white;
  background-color: var(--color-sedgwick-blue);
  border-radius: 4px;
  padding: 4px 6px 4px 6px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn-info:hover {
  background-color: #035e81;
}
.btn-info {
  font-size: 15px;
  color: #ffffff;
  line-height: 30px;
  border-radius: 40px;
  background: #244578;
  background-color: #007dbc;
  font-weight: bold;
}

.mat-tab-label-active {
  color: #035e81;
  background-color: #f1f4f7;
}

.mat-tab-label:hover {
  text-decoration: underline;
  text-decoration-color: #035e81;
  border-bottom: 2px solid #d2d8e1;
}

.tab-info {
  background-color: #f1f4f7;
  color: #035e81;
}

.error-message {
  color: #c24533 !important;
  border: 1px solid #c24533 !important;
}

.error-message-style {
  margin-top: 2px;
  margin-bottom: 2px;
}

.app-page-header-icon .mat-icon {
  height: 48px !important;
  width: 48px !important;
  font-size: 48px !important;
}

.app-uppercase {
  text-transform: uppercase;
}

.app-pointer {
  cursor: pointer;
  caret-color: transparent;
}

.app-color-teal {
  color: var(--color-teal) !important;
}

.app-color-slate {
  color: var(--color-slate);
}

.app-color-slate-600 {
  color: var(--color-slate-600);
}

.app-color-sedgwick-blue {
  color: var(--color-sedgwick-blue);
}

.app-color-input-grey {
  color: var(--color-input-grey);
}

.app-color-warn {
  color: var(--color-warn);
  font-weight: bold;
}
.app-color-warn:focus {
  color: var(--color-warn);
  font-weight: bold;
  outline-color: var(--color-warn) !important;
  padding: 1px !important;
}

.app-color-accent {
  color: var(--color-accent);
}

.app-bg-color-teal {
  background-color: var(--color-teal);
}

.app-bg-color-sedgwick-blue {
  background-color: var(--color-sedgwick-blue);
}

.app-bg-color-dark-blue {
  background-color: var(--color-dark-blue);
}

.app-bg-color-green {
  background-color: var(--color-green);
}

.app-bg-color-selected {
  background-color: #f1f4f7;
}

.app-bg-color-input-grey {
  background-color: var(--color-input-grey);
}

@mixin blue-focus-hover {
  outline-style: auto;
  outline-width: 3px;
  outline-color: #007dbc !important;
  --mat-tab-header-active-ripple-color: rgb(255, 255, 255) !important;
  --mat-tab-header-active-hover-indicator-color: rgb(255, 255, 255) !important;
  --mat-tab-header-active-focus-label-text-color: rgb(255, 255, 255) !important;
  --mat-tab-header-active-hover-label-text-color: rgb(255, 255, 255) !important;
  --mat-tab-header-active-focus-indicator-color: rgb(255, 255, 255) !important;
  --mat-tab-header-active-hover-indicator-color: rgb(255, 255, 255) !important;
  --mdc-tab-indicator-active-indicator-color: rgb(255, 255, 255) !important;
  --mat-tab-header-active-label-text-color: rgb(255, 255, 255) !important;
  --mat-tab-header-active-ripple-color: rgb(255, 255, 255) !important;
  --mat-tab-header-inactive-ripple-color: rgb(255, 255, 255) !important;
}

@mixin white-focus-hover {
  outline-style: auto;
  outline-width: 3px;
  outline-color: white !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.mat-mdc-tab.mdc-tab {
  height: 50px !important;
}
.mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
}

@media (min-width: 768px) {
  .comfloat-left {
    float: left !important;
  }
  .comfloat-right {
    float: right !important;
  }
  .comfloat-right-pdd {
    float: right !important;
    padding-right: 5em !important;
  }
}

.fullPanelFL {
  float: left;
}
.mat-mdc-form-field {
  display: unset !important;
}

.clear {
  clear: both;
}
.mdc-tab-indicator--active {
  font-weight: bold;
  color: #007dbc !important;
  text-decoration: none;
  border-bottom: 1px solid #007dbc !important;
}

::ng-deep .mdc-tab {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.mat-mdc-paginator-range-label {
  margin: 0px !important;
}

.mat-mdc-paginator-icon {
  fill: #67707a !important;
}

.mdc-tab-indicator__content--underline {
  border-color: #007dbc !important;
}
.mat-mdc-paginator-range-label {
  margin: 0px !important;
}

.mat-mdc-paginator-icon {
  fill: #67707a !important;
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: #67707a !important;
  opacity: 0.4;
}

.disabled-text {
  color: #757575;
  font-style: italic;
}

.mdc-menu-surface.mat-mdc-select-panel {
  background-color: #ffffff;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background-color: #f1f4f7;
}

.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
    .mdc-list-item--disabled
  ) {
  background-color: #d2d8e1;
}

@media (min-width: 700px) {
  .pdl40 {
    padding-left: 40px !important;
  }
  .pdl10 {
    padding-left: 10px !important;
  }
  .pdl30 {
    padding-left: 30px !important;
  }
  .pdl20 {
    padding-left: 20px !important;
  }
}
@media (max-width: 600px) {
  .pmt-header {
    vertical-align: top;
    padding-top: 8px !important;
  }
  .pmt-header-init {
    vertical-align: top;
  }
  .iconvalue {
    vertical-align: top;
    padding-top: 4px !important;
  }
  .iconheading::after {
    content: "" !important;
  }
  .mdc-dialog__title {
    padding-left: 14px !important;
  }
  .summaryheader {
    font-size: 18px !important;
  }
}
.headingbold {
  font-weight: bold;
}
.alert {
  padding: 20px;
  color: white;
  margin-bottom: 15px;
  text-align: left;
  border-radius: 5px;
}
.alert-danger {
  background-color: #9b57a2 !important;
}
.roundbtn {
  font-weight: 700;
  letter-spacing: 0px;
  border-radius: 24px !important;
}

.chatBubble {
  font-size: 38px;
  height: 40px;
  width: 50px;
  margin-top: 3px;
}
.openLiveChatBtn {
  color: #fff;
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  letter-spacing: 2px;
  line-height: 1.44em;
  vertical-align: middle;
  height: 48px;
  cursor: pointer;
  font-size: 18px;
  z-index: 99999;
  background-color: #01334c !important;
}
.spanLink {
  color: white !important;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.spanLink:hover {
  text-decoration: none;
  text-shadow: 1px 1px 1px #555;
}
@media (max-width: 700px) {
  #claimDetail .app-header-1 {
    font-size: 18px !important;
    color: #171c20;
    font-weight: bold;
  }
}

.btn-container {
  position: absolute;
  top: 35px;
  right: 55px;
}
.reportNewClmBtn {
  background-color: #007dbc !important;
  color: white !important;
}
.pdl15 {
  padding-left: 15px !important;
}
.pdr15 {
  padding-right: 15px !important;
}

@media (max-width: 500px) {
  .btn-container {
    position: relative;
    top: 0px;
    right: 0px;
  }
}

.pd0 {
  padding: 0px;
}

@media print {
  #onetrust-consent-sdk,
  #onetrust-banner-sdk,
  #onetrust-pc-sdk.ot-fade-in,
  #onetrust-pc-dark-filter.ot-fade-in {
    display: none !important;
  }
}

.titlecase {
  text-transform: lowercase !important;
  text-transform: capitalize !important;
}
.prevpage {
  color: #007dbc;
  font-weight: bold;
  font-size: 14pt;
}
.prevpage:hover {
  text-decoration: none;
  border-bottom: 1px solid #035e81;
}
.currentpage {
  color: #171c20 !important;
  opacity: 1 !important;
}

.web-form {
  border: 2px solid $color-sky-blue;
  border-radius: 5px;
}

.web-form-readonly {
  border: 2px solid darken($color-light-grey, 0%);
  border-radius: 5px;
}

.web-form-required {
  margin-bottom: 12px;
  display: inline-block;
}

.blue-required::after {
  content: "*";
  position: absolute;
}

.blue-required:not(.web-form-error)::after {
  color: silver;
}

.web-form-error.web-form-group,
.web-form-error[type="date"],
.web-form-error::placeholder,
.sub-text:has(.web-form-error)::after,
.blue-required:has(.web-form-error)::after {
  color: $color-red;
}

.web-form-error:not(.blue-required) {
  border-radius: 5px;
  border: 2px solid $color-red;
}

.web-form-ink {
  color: blue;
}

.web-form-paragraph {
  padding: 2px;
}

.sub-text:has(.web-form-error)::before {
  content: "Required";
  color: $color-red;
  display: inline-block;
  position: relative;
  top: 20px;
  width: 1px;
  left: 20px;
  -webkit-font-smoothing: antialiased;
  font-family: var(
    --mdc-typography-caption-font-family,
    var(--mdc-typography-font-family, Source Sans Pro)
  );
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
}

.sub-text:has([type="checkbox"]) {
  padding: 3px 2px 0 3px;
}

textarea.web-form {
  margin-bottom: -5px !important;
}

.web-form-group {
  padding: 2px 10px 0 10px;
}

.form-icon .icon {
  color: #567b22 !important;
}

.form-header .commCenterName {
  font-weight: bold;
}

.web-form-print {
  border-width: 0;
  position: relative;
  top: 8px;
}
textarea.web-form-print {
  background-color: #f0f0f0;
}

/* Override default tooltip styling to a light theme */
.mdc-tooltip__surface {
  background-color: white !important;
  color: black !important;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2) !important;
}

div:not([type="text"]) {
  caret-color: transparent;
}
input {
  caret-color: var(--color-sedgwick-blue);
}

// Spinners generated from: https://www.cssportal.com/css-loader-generator/
.app-spinner-white {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, var(--color-white));
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
  animation: app-spinner-kf 1s infinite linear;
}

.app-spinner-grey {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #888888);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
  animation: app-spinner-kf 1s infinite linear;
}

.app-spinner-grey-24 {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #888888);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
  animation: app-spinner-kf 1s infinite linear;
}

.app-rl8-selectall .select-all {
  position: relative;
  left: -8px;
  top: -10px;
}

@keyframes app-spinner-kf {
  to {
    transform: rotate(1turn);
  }
}

// scrollbar
* {
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1 #f1f1f1;
}

*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f1f1f1;
}

*::-webkit-scrollbar-track:hover {
  background-color: #f1f1f1;
}

*::-webkit-scrollbar-track:active {
  background-color: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #c1c1c1;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #c1c1c1;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #c1c1c1;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
}

.reminder-list {
  font-weight: normal;
  font-size: $app-size-2;
}

.toolbar-icon-blue {
  color: $color-sedgwick-blue;
}

.toolbar-icon-slate {
  color: $color-slate;
}

.my-sedgwick-badge-accent.mat-badge-accent .mat-badge-content {
  background-color: $color-teal;
  color: #ffffff;
}

.app-blue-container {
  background-color: #eef6fa;
  border-bottom: 8px solid #deeef6;
}

.containers-min-height .container {
  min-height: 427px;
}

.app-color-sedgwick-blue {
  color: $color-sedgwick-blue;
}

.tams-bubble > div > div:nth-child(2) {
  // background-color: #fff8c0;
  // border: 1px solid $color-teal;
  // border-radius: 5px;
  // padding-left: 5px;

  font-size: 10px;
  font-weight: bold;
  color: white;
  background-color: var(--color-sedgwick-blue);
  border-radius: 4px;
  padding: 4px 6px 4px 6px;
  margin-left: 4px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

// Screen reader only class
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
  border-width: 0;
}

.mdc-icon-button {
  outline-style: none;
  &:focus,
  &:active,
  &:hover,
  &:active:focus {
    outline-style: solid;
    outline-width: 2px;
    outline-color: var(--color-sedgwick-blue);
    background-color: white;
    --mat-icon-button-state-layer-color: #fff;
  }
}
.low-emphasis {
  outline-style: none;
  &:focus,
  &:active,
  &:hover,
  &:active:focus {
    outline-style: solid;
    outline-width: 2px;
    background-color: white;
    --mat-icon-button-state-layer-color: #fff;
    outline-color: #67707a !important;
  }
}

.dialog-container {
  min-height: 0;
  max-height: 100vh;
}
@media (max-height: 300px) {
  .dialog-container {
    max-height: 90vh;
  }
}

.table-header {
  color: white;
  background-color: var(--color-teal);
  height: 48px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  line-height: 48px;
  border-color: white;
  border-width: 1px;
}
.table-header-left {
  color: white;
  border-color: white;
  border-width: 1px;
  background-color: var(--color-teal);
  padding: 5px;
}
@media (max-width: 480px) {
  .table-header {
    font-size: 12px;
    line-height: 26px;
    word-break: break-word;
    white-space: normal;
    font-weight: normal;
    border-color: white;
    border-width: 1px;
  }
  .table-header-left {
    font-size: 12px;
    word-break: break-word;
    white-space: normal;
    font-weight: normal;
    padding: 2px;
  }
}

.logout {
  color: $color-slate !important;
}

.logout {
  &:focus,
  &:hover {
    outline-style: solid !important;
    outline-width: 2px !important;
    background-color: white !important;
    outline-color: #007dbc !important;
    color: #171c20 !important;
  }
}

.toolbar-icon-blue {
  color: var(--color-sedgwick-blue) !important;
}
html {
  --mdc-outlined-text-field-error-label-text-color: #c24533 !important;
  --mdc-outlined-text-field-error-hover-label-text-color: #c24533 !important;
  --mdc-outlined-text-field-error-focus-outline-color: #c24533 !important;
  --mdc-outlined-text-field-error-hover-outline-color: #c24533 !important;
  --mdc-outlined-text-field-error-outline-color: #c24533 !important;
  --mat-form-field-error-text-color: #c24533 !important;
  --mdc-outlined-text-field-focus-outline-color: #007dbc !important;
  --mdc-outlined-text-field-caret-color: #007dbc !important;
  --mdc-outlined-text-field-hover-label-text-color: #007dbc !important;
  --mdc-outlined-text-field-label-text-color: #67707a !important;
  --mdc-outlined-text-field-outline-color: #67707a !important;
  --mat-form-field-text-color: #c24533 !important;
  --mat-form-field-focus-outline-color: #007dbc !important;
  --mdc-filled-text-field-caret-color: #c24533 !important;
  --mdc-filled-text-field-focus-active-indicator-color: #007dbc !important;
  --mdc-filled-text-field-focus-label-text-color: #007dbc !important;
  --mdc-outlined-text-field-focus-label-text-color: #007dbc !important;
  --mdc-checkbox-focus-outline-color: #007dbc !important;
  --mdc-outlined-text-field-hover-label-text-color: #007dbc !important;
  --mdc-outlined-text-field-hover-outline-color: #171c20 !important;
}

.mdc-tab {
  &:hover,
  &:focus {
    @include blue-focus-hover;
  }
}

.cglass {
  color: #67707a !important;
}

:focus {
  outline-style: solid;
  outline-width: 2px;
  outline-color: #007dbc !important;
}

.blue-focus-border:focus {
  outline-style: solid;
  outline-width: 2px;
  outline-color: #007dbc !important;
}

.white-focus-border:focus {
  outline-style: solid;
  outline-width: 2px;
  outline-color: #ffffff !important;
}
.mat-mdc-slide-toggle {
  padding: 4px 8px 4px 8px !important;
}
.mat-mdc-slide-toggle {
  &:hover,
  &:focus {
    outline-style: solid;
    outline-width: 3px !important;
    outline-color: #007dbc !important;
    padding: 4px 8px 4px 8px !important;
  }
}

.mdc-switch {
  &:hover,
  &:focus,
  &:active {
    outline-style: solid;
    outline-width: 3px !important;
    border-radius: 10px;
    outline-color: #007dbc !important;
    padding: 8px !important;
  }
}
.mdc-checkbox {
  &:hover,
  &:active,
  &:focus &:focus-visible {
    outline-style: solid;
    outline-width: 3px !important;
    border-radius: 10px;
    outline-color: #007dbc !important;
    padding: 12px !important;
    border-radius: 67px;
    box-shadow: none !important;
  }
}

.mdc-checkbox:focus {
  outline: none;
}

.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
    .mdc-list-item--disabled
  ) {
  background-color: #fff !important;
  outline-style: auto;
  outline-color: #67707a !important;
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: #035e81 !important;
  font-size: 14px !important;
  font-weight: bold !important;
}
div.mat-mdc-select-panel .mat-mdc-option:hover {
  font-size: 14px !important;
  font-weight: bold !important;
  outline-color: #67707a !important;
  outline-style: auto;
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  font-size: 14px !important;
  font-weight: bold !important;
  outline-color: #67707a !important;
  outline-style: auto;
}
.mat-mdc-option {
  font-size: 14px !important;
}

.app-mat-list-item {
  &:hover &:active {
    @include white-focus-hover;
  }
}

.app-mat-list-item:focus {
  &:focus {
    @include white-focus-hover;
  }
}

.high-emphasis:hover {
  outline-style: none !important;
  background-color: var(--color-teal) !important;
}
.high-emphasis:active {
  background-color: #15819d !important;
  color: white;
}
.high-emphasis:focus {
  background-color: #0c3243 !important;
  color: white;
  outline: 1px solid black !important;
}

.watchlist-overlay,
.history-overlay {
  min-width: 750px;
}

.viewDifferentClaimOverlay {
  width: auto;
}

.ot-pc-refuse-all-handler,
.save-preference-btn-handler {
  background-color: #007dbc !important;
  color: white;
  opacity: 1 !important;
}

::ng-deep .mat-mdc-option:focus.mdc-list-item,
.mat-mdc-option.mat-mdc-option-active {
  border: 2px solid #67707a !important;
  background-color: #d7d7d7 !important;
  /* border-radius: 50px 20px; */
}

.app-mat-list-item-span {
  font-size: 0.8125em;
  font-weight: bold;
  color: white !important;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.footer-font {
  font-family: "Source Sans Pro", "Helvetica Neue", sans-serif;
  font-weight: 700;
  font-size: 0.75em;
  letter-spacing: 0.2em;
}

.footer {
  color: white;
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 0.2em;
  background-color: #01334c;
  padding: 20px;
}

.save-preference-btn-handler {
  :hover &:focus {
    background-color: #035e81 !important;
    color: white;
    opacity: 1 !important;
    outline: auto !important;
    outline-color: black !important;
  }
}

.ot-pc-refuse-all-handler {
  :hover &:focus {
    background-color: #035e81 !important;
    color: white;
    opacity: 1 !important;
    outline: auto !important;
    outline-color: black !important;
  }
}

#onetrust-pc-sdk .ot-pc-refuse-all-handler:hover {
  outline-color: black;
  border: 2px solid black !important;
}
#onetrust-pc-sdk .save-preference-btn-handler:hover {
  border: 2px solid black !important;
}

// To test WCAG spacing requirements
// * {
//   line-height: 1.5 !important;
//   letter-spacing: 0.12em !important;
//   word-spacing: 0.16em !important;
// }
// p {
//   margin-bottom: 2em !important;
// }
